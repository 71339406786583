import React from "react";
import {Link} from "gatsby";

class Navbar extends React.Component {
    render()
    {
        return (
            <div className="nav--container">
                <div className={'border-t-2 border-blue-500'}>
                    <nav
                        className={'container px-2 py-2 sm:px-2 sm:py-0 sm:mx-auto flex items-center justify-between flex-wrap'}>
                        {this.props.children}
                    </nav>
                </div>
            </div>
        )
    }
}


class NavbarBrand extends React.Component {
    render()
    {
        return (
            <div className="flex items-center flex-shrink-0 mr-6 ml-0 pl-0">
                <Link to={this.props.href}
                      className="font-semibold text-xl text-grey-900 tracking-tight decoration-none">{this.props.children}</Link>
            </div>
        )
    }
}


class NavbarToggle extends React.Component {

    constructor(props)
    {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle()
    {
        this.props.onToggleMenu();
    }

    render()
    {
        return (
            <div className="block sm:hidden">
                <button
                    onClick={this.toggle}
                    className="flex items-center px-3 py-2 border rounded text-grey-700 border-grey-700 hover:bg-grey-100">
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div>
        )
    }
}


class NavbarItem extends React.Component {
    render()
    {
        return (
            <Link to={this.props.link}
                  activeClassName="active"
                  className="navitem">
                {this.props.icon}
                {this.props.title}
            </Link>
        )
    }
}


class NavbarMenu extends React.Component {

    render()
    {
        return (
            <div className="sm:flex-grow">
                {this.props.children}
            </div>
        )
    }
}


export {
    Navbar,
    NavbarBrand,
    NavbarToggle,
    NavbarItem,
    NavbarMenu
}
