import React from 'react';

import {Navbar, NavbarBrand, NavbarItem, NavbarMenu, NavbarToggle} from './navbar';

import ToStitchLogo from '../img/ToStitch-logo.svg';
import LanguageIcon from '../img/font-awesome/light/globe.svg';


export default class Header extends React.Component {

    state = {showMenu: false};

    constructor(props)
    {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
    }


    toggleMenu(isOpen)
    {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }


    render()
    {
        const menuActive = this.state.showMenu ? '' : 'hidden';

        let itemsLeft, itemsRight, homeUrl;

        if (this.props.lang === "de")
        {
            homeUrl = "/de/";

            itemsLeft = [
                <NavbarItem key="1" title={'2stitch Organizer'} link={'/de/'}/>,
                <NavbarItem key="2" title={'2stitch Studio'} link={'/de/studio/'}/>
            ];

            itemsRight = [
                <NavbarItem key="50" title={'Lernen'} link={'/de/lernen/'}/>
            ];

        }
        else
        {
            homeUrl = "/";

            itemsLeft = [
                <NavbarItem key="1" title={'2stitch Organizer'} link={'/'}/>,
                <NavbarItem key="2" title={'2stitch Studio'} link={'/studio/'}/>
            ];

            itemsRight = [
                <NavbarItem key="50" title={'Learn'} link={'/learn/'}/>
            ];
        }

        if(this.props.translated)
        {
            let icon = <LanguageIcon className="svg-inline--fa mr-1"/>;

            if(this.props.translated['de'])
            {
                itemsRight.push(
                    <NavbarItem key="translated" title={'Deutsch'} link={this.props.translated['de']} icon={icon}/>
                );
            }
            else
            {
                itemsRight.push(
                    <NavbarItem key="translated" title={'English'} link={this.props.translated['en']} icon={icon}/>
                );
            }
        }


        return (
            <Navbar>
                <NavbarBrand href={homeUrl} className="text-primary">
                    <ToStitchLogo style={{width: '6rem'}} className='mr-12'/>
                </NavbarBrand>
                <NavbarToggle onToggleMenu={this.toggleMenu}/>

                <div className={`w-full block ${menuActive} flex-grow sm:flex sm:items-center sm:w-auto`}>
                    <NavbarMenu key="left">
                        {itemsLeft.map((t)=>t)}
                    </NavbarMenu>
                </div>

                <div className={`w-full block ${menuActive} sm:flex sm:items-center sm:w-auto`}>
                    <NavbarMenu key="right">
                        {itemsRight.map((t)=>t)}
                    </NavbarMenu>
                </div>
            </Navbar>
        );
    }
}
