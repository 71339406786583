import React from 'react'

import Layout from '../components/layout';
import AsyncDownload from '../components/async-download';

import {graphql} from 'gatsby';

import DownloadWinChrome from "../img/SVG/download-chrome-win.svg";
import DownloadWinEdge from "../img/SVG/download-edge-win.svg";
import DownloadWinFirefox from "../img/SVG/download-firefox-win.svg";
import NonStretchedImage from '../components/non-stretchd-image';
const {detect} = require('detect-browser');


function justFileName(url)
{
    return url.match(/([^/]*)$/)[0];
}

class DownloadPageWindows extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            arch: "32",
            archOther: "64",
            downloadUrl: this.props.data.site.siteMetadata.downloadWin32,
            downloadUrlOther: this.props.data.site.siteMetadata.downloadWin64,
            fileName: justFileName(this.props.data.site.siteMetadata.downloadWin32),
            browser: "edge"
        };
    }

    componentDidMount()
    {
        const platform = navigator.platform;
        const agent = navigator.userAgent;
        const browser = detect();

        const o = /x64[;)]|x86-64|x86_64|x64-64|x64_64|Win64|WOW64|amd64|ia64/i;
        if (o.test(platform) || o.test(agent))
        {
            this.setState({
                arch: "64",
                archOther: "32",
                downloadUrl: this.props.data.site.siteMetadata.downloadWin64,
                downloadUrlOther: this.props.data.site.siteMetadata.downloadWin32,
                fileName: justFileName(this.props.data.site.siteMetadata.downloadWin64),
                browser: browser.name
            });
        }
    }

    render()
    {
        let text;

        if (this.state.browser === "chrome")
        {
            text = <>
                <p>
                    Please wait until the download is completed, then click on the
                    button with the text <b>{this.state.fileName}</b> in the bottom left corner of
                    this window.
                </p>
                <div className={'text-center'}>
                    <DownloadWinChrome style={{maxWidth: "30rem"}}/>
                </div>
                <p>
                    In the upcoming dialog click <b>Ok</b> to start the installer.
                </p>
            </>

        }
        else if (this.state.browser === "firefox")
        {
            text = <>
                <p>
                    FireFox asks you what you want to do with the <b>{this.state.fileName}</b>.
                    Click <b>Save file</b> to start the download, next click on the arrow
                    at the top of the browser window:
                </p>
                <div className={'text-center'}>
                    <DownloadWinFirefox style={{maxWidth: "30rem"}}/>
                </div>
                <p>
                    Wait until the download is completed, then click on <b>{this.state.fileName}</b>
                    Click <b>Ok</b> to start the installer.
                </p>
            </>
        }
        else /* Edge / IE */
        {
            text = <>
                <p>
                    Your browser asks you what you want to do with the
                    downloaded <b>{this.state.fileName}</b>. Click the <b>Run</b> button to start
                    the installation after the download.
                </p>
                <div className={'text-center'}>
                    <DownloadWinEdge style={{maxWidth: "30rem"}}/>
                </div>
                <p>
                    Microsoft runs a security scan on the file to verify that it does not
                    contain malware. The installation starts right after the check is finished.
                </p>
            </>
        }

        return (

            <Layout lang={'en'}
                    className={'nav--no_border'}
                    title={'2stitch Organizer - Free embroidery file viewer for Windows'}
                    description={'View, edit, convert or tag your embroidery files on your computer. Supports: Brother (PES), Tajima (DST), Husquarna (HUS/VIP), Janome (JEF/SEW) and more.'}
                    translated={{de:'/de/download-organizer-windows/'}}
                    location={this.props.location.pathname}
            >

                <div className={'bg-blue-50 text-blue-900 pb-12 pt-12 border-t border-b border-blue-900'}>
                    <div className="container mx-auto">

                        <AsyncDownload
                            url={this.state.downloadUrl}
                            productName={'2stitch Organizer'}
                        />
                        <div className="text-center">
                            <small className="text-center inline-block mt-4">
                                You are currently downloading the version for {this.state.arch}bit windows.<br/>
                                Click here to download the <a href={this.state.downloadUrlOther}>{this.state.archOther}bit
                                version</a>.
                            </small>
                        </div>
                    </div>
                </div>

                <div className={'container mx-auto mt-12 markdown'}>
                    <h2 className={'mb-8 text-3xl'}>Downloading 2stitch Organizer on your Windows PC</h2>
                    {text}
                    <p>
                        If this does not work for you try to find your <b>Downloads</b> folder in Explorer.
                        Double-click the <b>{justFileName(this.state.downloadUrl)}</b> to start the installer.
                    </p>


                    <h2>Installing 2stitch Organizer</h2>

                    <p>The installer should open the following window. Click <b>Next</b>:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install1.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Please take your time to read the license agreement. Click the
                        Checkbox next to <b>I accept the terms in the license agreement.</b> Click <b>Next</b> to continue:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install2.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Click <b>Next</b> to continue:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install3.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Click <b>Next</b> to start the installation:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install4.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Click <b>Yes</b> to agree to install 2stitch Organizer:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install5.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Click <b>Finish</b> to end the installation</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install6.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>
                        You should now see the <b>2stitch Organizer</b> icon on your desktop.
                        Double click it to start the program.
                    </p>
                </div>

            </Layout>
        )
            ;
    }
}

export default DownloadPageWindows;




export const query = graphql`
    query DownloadPageWindowsQuery {
        site {
            siteMetadata {
                downloadWin32
                downloadWin64
            }
        }

        install1: file(relativePath: { eq: "organizer/install-win-1.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }

        install2: file(relativePath: { eq: "organizer/install-win-2.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }

        install3: file(relativePath: { eq: "organizer/install-win-3.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }

        install4: file(relativePath: { eq: "organizer/install-win-4.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                }
            }
        }

        install5: file(relativePath: { eq: "organizer/install-win-5.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                }
            }
        }

        install6: file(relativePath: { eq: "organizer/install-win-6.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }
    }
`;
