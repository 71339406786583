import React from 'react'
import Link from 'gatsby-link'

class Footer extends React.Component {

    render()
    {
        return <footer className="py-4 border-t border-blue-800">
            <div className="container mx-auto flex justify-between">
                <div>
                    2stitch &copy; {(new Date().getFullYear())} by CodeAndWeb GmbH
                </div>
                <div>
                    {(this.props.lang === "en") ?
                        <>
                            <Link to="/about/" className="mr-2">Imprint</Link> |
                            <Link to="/privacy/" className="mr-2 ml-2">Privacy Policy</Link>
                        </>
                        :
                        <>
                            <Link to="/de/impressum/" className="mr-2 ml-2">Impressum</Link> |
                            <Link to="/de/datenschutz/" className="ml-2">Datenschutz</Link>
                        </>
                    }
                </div>
            </div>
        </footer>
    }
}


export default Footer;
