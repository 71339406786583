import React from 'react'
import {graphql, StaticQuery} from 'gatsby'

import Header from './header'
import Footer from './footer'
import SEO from './seo'

import {addLocaleData, IntlProvider} from "react-intl";

import '../css/style.css'

import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';

import messages_de from "../translations/de.json";
import messages_en from "../translations/en.json";

import Helmet from 'react-helmet'

addLocaleData([...locale_en, ...locale_de]);

const messages = {
    'de': messages_de,
    'en': messages_en
};

class Debug extends React.Component {
    render()
    {
        return (process.env.NODE_ENV === 'development') ?
            <div className="debug-info fixed pin-r pin-t mr-2 p-1 bg-grey-900 text-grey-50">
                <div className="sm:hidden">&lt;SM</div>
                <div className="hidden sm:block md:hidden">SM</div>
                <div className="hidden md:block lg:hidden">MD</div>
                <div className="hidden lg:block xl:hidden">LG</div>
                <div className="hidden xl:block ">XL</div>
                <div className={'debug-info__hidden'}>
                    <table>
                        <tbody>
                        <tr>
                            <td>title</td>
                            <td>{this.props.title}</td>
                        </tr>
                        <tr>
                            <td>descr</td>
                            <td>{this.props.description}</td>
                        </tr>
                        <tr>
                            <td>excluded</td>
                            <td>{this.props.excludeFromIndex ? "YES" : "no"}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            : ""
    }
};


class Layout extends React.Component {

    render()
    {
        let meta = [
            {name: 'charSet', content: 'utf-8'}
        ];


        if (this.props.excludeFromIndex)
        {
            meta.push({'name': 'robots', 'content': 'noindex'});
        }

        // add translations
        let hreflang = [];
        if (this.props.translated)
        {
            hreflang = Object.keys(this.props.translated).map(lang => {
                let url = this.props.translated[lang];
                return url ? <link rel="alternate"
                                   href={this.props.site.siteMetadata.siteUrl + url}
                                   hreflang={lang}
                                   key={"lang-other-"+lang}/> : null
            });
        }

        // make en the default
        let thisUrl = this.props.site.siteMetadata.siteUrl + this.props.location;

        let defaultUrl = thisUrl;
        if(this.props.translated && this.props.translated['en'])
        {
            defaultUrl = this.props.site.siteMetadata.siteUrl + this.props.translated['en'];
        }

        return (
            <>
                <Helmet
                    htmlAttributes={{lang: this.props.lang}}
                    title={this.props.title}
                    meta={meta}
                    defer={false}
                >
                    {hreflang}
                    <link rel="alternate" hreflang="x-default" href={defaultUrl}/>

                    <link rel="alternate" hreflang={this.props.lang}
                                        href={thisUrl}
                                        key={"lang-this-"+this.props.lang}/>
                </Helmet>

                <SEO isBlogPost={false}
                     postData={{}}
                     postImage={''}
                     title={this.props.title}
                     description={this.props.description}
                />

                <IntlProvider locale={this.props.lang} messages={messages[this.props.lang]}>

                    <div className={this.props.className + " flex flex-col"} style={{minHeight: '100vh'}}>
                        <Header siteTitle={this.props.site.siteMetadata.title}
                                lang={this.props.lang}
                                translated={this.props.translated}/>

                        <Debug title={this.props.title}
                               description={this.props.description}
                               excludeFromIndex={this.props.excludeFromIndex}
                        />

                        <div className={'flex-1 ' + this.props.centerClassName}>
                            {this.props.children}
                        </div>

                        <Footer lang={this.props.lang}/>
                    </div>
                </IntlProvider>
            </>
        );
    }
}


export default props => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site: site {
                    siteMetadata {
                        title
                        siteUrl
                    }
                }
            }
        `}

        render={({site}) => <Layout site={site} {...props} />}
    />
);
