import React from 'react'

import ReactTimeout from 'react-timeout'

import {FormattedHTMLMessage} from 'react-intl';


class AsyncDownload extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {downloading: false};
        this.triggerDownload = this.triggerDownload.bind(this);
    }

    componentDidMount()
    {
        this.props.setTimeout(this.triggerDownload, 1000);
    }

    triggerDownload()
    {
        window.location.href = this.props.url;
        this.setState({downloading: true});
    }

    render()
    {
        return this.state.downloading ?
            <div className="text-center">
                <h1 className={"text-5xl mb-12"}>
                    <FormattedHTMLMessage id={"async-download.thanks"} values={{productName:this.props.productName}}/>
                </h1>
                <p className={'text-xl'}>
                    <FormattedHTMLMessage id={"async-download.click-here"} values={{url:this.props.url}}/>
                </p>
            </div>

            :

            <div className="text-center">
                <h1 className={"text-5xl mb-12"}><FormattedHTMLMessage id={"async-download.downloading"}/></h1>
                <p className={'text-xl'}>
                    <FormattedHTMLMessage id={"async-download.click-here"} values={{url:this.props.url}}/>
                </p>
            </div>

            ;
    }
}


export default ReactTimeout(AsyncDownload);